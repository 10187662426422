@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

/* fieldset legend p {
  font-size: 12px !important;
  line-height: '1.2em';
  text-overflow: unset; */
/* } */

/* .MuiInputLabel-outlined {
  width: 100%;
  padding-right: 2em;
} */
